// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/bootstrap/dist/css/bootstrap.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);"]);
// Module
exports.push([module.id, "body{background-color:#f5f5f5;font-family:'Montserrat', sans-serif}.formControl{height:45px;font-size:18px;border:1px solid #DDDDDD;border-radius:2px;box-shadow:none;-webkit-box-shadow:none;-moz-box-shadow:none}.form{margin:30px 0}.form-group{margin-bottom:28px;font-size:18px}.control-label{font-weight:400;margin-bottom:5px}.form-control{padding:9px 12px;border-radius:2px;color:#555}.form-control:focus{border-color:#66afe9;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6)}@media (min-width: 768px){.nav-buttons{display:flex;align-items:center;justify-content:flex-end}.nav-buttons>*{margin-left:10px}}.form-error{margin-top:28px;color:#f00}.button-label{color:#888;font-size:14px;font-style:italic}.Page{min-height:100vh;display:flex;flex-direction:column;align-items:stretch}.Page-content{flex-grow:1}.Page-footer{flex-shrink:0;align-self:center;margin:25px 0 10px}.message{position:fixed;top:20px;right:20px;min-width:300px;max-width:500px}\n", ""]);
// Exports
module.exports = exports;

export const categories = [
  {
    description: 'Abonnementsregister',
    title: 'Abonnement',
    name: 'abonnement',
    manual: true,
    deleteAllowed: true,
    informationType: 'navn, efternavn, adresse, email, telefon, cpr nr., konto oplysninger, betalinger,  ordrehistorik, transaktionsdata',
    purpose: 'At servicere kundens abonnementer',
    authority: 'Kontrakt. Databehandling foretages af hensyn til opfyldelse af en kontrakt, som kunden er part i.',
    access: 'Relevante medarbejdere i Berlingske Media\'s afdeling for abonnement, salg og kundeservice',
    origin: 'Indkomne indtastede ordrer, Kundeunivers, register for log-in oplysninger, offentligt adresseregister (AWS)',
    registrer: 'Abonnenter og tidligere abonnenter',
    storeLongevity: '2-5 år efter brug'
  },
  {
    description: 'Internt produktionsregister til rapportering mv.',
    title: 'Rapportering',
    name: 'rapportering',
    manual: true,
    deleteAllowed: true,
    informationType: 'Navn, adresse, email, telefon, ordrenummer, kundenummer, beskrivelse af kundeinteraktioner',
    purpose: 'At understøtte Berlingske Media\'s interne produktionsstyring og ledelsesrapportering.',
    authority: 'Legitime interesser. Databehandling foretages af hensyn til Berlingske Media\'s produktionsstyring og ledelsesrapportering.',
    access: 'Relevante medarbejdere i Berlingske Media\'s afdeling for rapportering',
    origin: 'En lang række af de øvrigt nævnte registre, Geomatic (Robinson list)',
    registrer: 'Abonnenter og tidligere abonnenter. Læsere af Berlingske Media\'s nyheds-sites med log-in.',
    storeLongevity: '2-5 år efter brug'
  },
  {
    description: 'Register for Kundeoplysninger, Marketing og nyhedsbreve',
    title: 'Kundeoplysninger',
    name: 'kundeoplysninger',
    manual: false,
    deleteAllowed: true,
    informationType: 'Navn, email, adresse, fødselsdag, telefon, børn, interesser, nyhedsbreve, samtykker',
    purpose: 'At administrere stamoplysninger, interesser, nyhedsbreve, samtykker på Berlingske Media\'s kunder. At understøtte Berlingske Media\'s marketing. At servicere kundens abonnementer på nyhedsbreve',
    authority: 'Læserens samtykke. Desuden kontrakt. Databehandling foretages af hensyn til opfyldelse af aftaler, som kunden er part i.',
    access: 'Relevante medarbejdere i Berlingske Media\'s afdeling for salg og marketing.',
    origin: 'kundeunivers, Abonnementsregister, manuelle indtastninger og Oplysninger afgivet af Kunden online',
    registrer: 'Alle kunder i Berlingske Media',
    storeLongevity: '0-5 år efter brug'
  },
  {
    description: 'Register for log-in oplysninger',
    title: 'log-in',
    name: 'login',
    manual: false,
    deleteAllowed: true,
    informationType: 'Navn, adresse, ordrenumre, abonnementer',
    purpose: 'At give kunden single-sign-on på Berlingske Media\'s systemer',
    authority: 'Kontrakt. Databehandling foretages af hensyn til opfyldelse af en aftale om kontooprettelse, som kunden er part i.',
    access: 'Kun maskinel adgang fra Berlingske Media\'s IT-systemer og relevante medarbejdere hos Berlingske Media\'s kundeservice',
    origin: 'Abonnementsregister, Kundeunivers',
    registrer: 'Abonnenter og tidligere abonnenter',
    storeLongevity: 'Umiddelbart efter nedlæggelse af konto'
  },
  {
    description: 'Register for markedsundersøgelser, quizzer, spørgeundersøgelser og konkurrencer',
    title: 'Markedsundersøgelse',
    name: 'markedsundersogelse',
    manual: true,
    deleteAllowed: true,
    informationType: 'Navn, email, adresse, køn, alder, beskæftigelse, uddannelse, husstandsindkomst, børn, postnummer',
    purpose: 'At gennemføre quizzer, tilfredshedsundersøgelser mv.',
    authority: 'Læserens samtykke.',
    access: 'Relevante medarbejdere i Berlingske Media\'s afdeling for Markedsanalyse',
    origin: 'Abonnementsregister og oplysninger afgivet af kunden i tilfredshedsundersøgelser mv.',
    registrer: 'Abonnenter og tidligere abonnenter. Læsere af Berlingske Media\'s nyheds-sites med log-in.',
    storeLongevity: '3-5 år efter brug'
  },
  {
    description: 'Register for online-trafik',
    title: 'Tracking',
    name: 'tracking',
    manual: true,
    deleteAllowed: true,
    informationType: 'Log-in navn, viste annoncer, Oplysninger om klik på annoncer og artikler, tekniske oplysninger om kundens device',
    purpose: 'At tilbyde den digitale læser en god brugeroplevelse med relevant sideorganisering, indhold og annoncering',
    authority: 'Læserens samtykke.',
    access: 'Kun maskinel adgang fra Berlingske Media\'s IT-systemer.',
    origin: 'Opsamling på vores nyhedssites',
    registrer: 'Læsere af Berlingske Media\'s nyheds-sites med log-in.',
    storeLongevity: '6 mnd-5 år'
  },
  {
    description: 'Register for telemarketing',
    title: 'Telemarketing',
    name: 'telemarketing',
    manual: true,
    deleteAllowed: true,
    informationType: 'Navn, adresse, telefon, lydfiler med foretagede opkald',
    purpose: 'At understøtte Berlingske Media\'s afdeling for salg og marketing.',
    authority: 'Legitime interesser. Databehandling foretages af hensyn til Berlingske Media\'s salg og marketing.',
    access: 'Relevante medarbejdere i Berlingske Media\'s afdeling for salg og marketing.',
    origin: 'Abonnementsregister, offentlige telefonlister',
    registrer: 'Abonnenter og tidligere abonnenter, registredede på offentlige telefonlister.',
    storeLongevity: '5 år efter brug'
  },
  {
    description: 'Register over henvendelser til kundeservice',
    title: 'Kundeservice',
    name: 'kundeservice',
    manual: true,
    deleteAllowed: false,
    informationType: 'Navn, efternavn, adresse, email, telefon og evt andre afgivne oplysninger',
    purpose: 'At servicere kundens henvendelse',
    authority: 'Kontrakt. Databehandling foretages af hensyn til opfyldelse af en kontrakt, som kunden er part i.',
    access: 'Relevante medarbejdere i Berlingske Media\'s kundeservice',
    origin: 'Fra kunden ved henvendelse',
    registrer: 'Kunder der har henvendt sig til Berlingske Media\'s kundeservice',
    storeLongevity: '5 år efter brug'
  },
  {
    description: 'Register over indrykkede annoncer og anden data i forbindelse med kontakt til Berlingske Media ́s annoncesalg',
    title: 'Annoncering',
    name: 'annoncering',
    manual: true,
    deleteAllowed: false,
    informationType: 'Navn, email, funktionsområde, titel, telefon, adresse, ordrehistorik, aktivitetshistorik',
    purpose: 'At understøtte kundens ordrebestilling og samarbejde med Berlingske Media ́s annonceafdeling',
    authority: 'Kontrakt. Databehandling foretages af hensyn til opfyldelse af aftalte handler eller på baggrund af kundens interaktion med Berlingske Media ́s annonceafdeling',
    access: 'Relevante medarbejdere i Berlingske Media\'s annonceafdeling samt systemleverandører og systempartnere.',
    origin: 'Fra kunden ved ordreafgivelse eller kontakt med Berlingske  Media ́s annonceafdeling   (telefonisk, pr mail, online, deltagelse i arrangementer)',
    registrer: 'Kunder der har indrykket annoncer hos Berlingske Media eller har haft kontakt med Berlingske Media ́s annonceafdeling',
    storeLongevity: '2-5 år efter brug'
  }
];

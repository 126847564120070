import { configure } from '@berlingske-media/bm.node-module.selfservice-sdk';
import { authProvider } from './authProvider';

const getApiUrl = () => {
    return `${process.env.SELF_SERVICE_URL}/api`;
}

const ssSDK = configure(getApiUrl(), authProvider);

export { ssSDK };

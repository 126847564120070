import { actionBuilder } from '../common/redux-utils';
import { ssSDK } from "../common/client/selfservice";
import { userSDK } from '../common/client/user';
import { logOut } from '../logout/logOut.actions';
import gigyaAuthCredentials from '../common/gigya-auth-credentials';
import { fetchUserInfo } from '../common/userInfo.actions';

export const DELETE_ALL_SHOW_CONFIRM = '[delete all] show confirm';
export const DELETE_ALL_CANCEL_CONFIRM = '[delete all] cancel confirm';

export const showConfirm = actionBuilder(DELETE_ALL_SHOW_CONFIRM);
export const cancelConfirm = actionBuilder(DELETE_ALL_CANCEL_CONFIRM);

export const DELETE_ALL_REQUEST_SUBSCRIPTION_STATUS = '[delete all] request subscription status';
export const DELETE_ALL_RECEIVE_SUBSCRIPTION_STATUS = '[delete all] receive subscription status';
export const subscriptionStatusRequest = actionBuilder(DELETE_ALL_REQUEST_SUBSCRIPTION_STATUS);
export const receiveSignatureStatus = (signatureStatus) => ({
    type: DELETE_ALL_RECEIVE_SUBSCRIPTION_STATUS,
    signatureStatus
});

export const DELETE_ALL_REQUEST = '[delete all] request';
export const DELETE_ALL_RECEIVE = '[delete all] receive';
export const DELETE_ALL_RESET = '[delete all] reset';
export const DELETE_ALL_ERROR = '[delete all] error';

export const request = actionBuilder(DELETE_ALL_REQUEST);
export const receive = actionBuilder(DELETE_ALL_RECEIVE);
export const reset = actionBuilder(DELETE_ALL_RESET);
export const error = error => {
    let message = '';
    let allowRetry = false;
    switch (error.errorCode) {
        case 402:
            message =  'Du kan ikke slette din data, da din balance ikke er i 0. Kontakt venligst kundeservice';
            break;
        case 403:
            allowRetry = true;
            break;
        default:
            message = 'Dele af dine data kan ikke bekræftes slettet.';
            allowRetry = true;
            break;
    }
    return {
        type: DELETE_ALL_ERROR,
        error,
        message,
        allowRetry,
    };
}

export const finalize = () => {
  return dispatch => {
    dispatch(reset());
    dispatch(logOut());
  };
};

export const checkIfSubscriptionActive = () => {
    return (dispatch, getState) => {
        const { apiData } = getState();

        dispatch(subscriptionStatusRequest());

        ssSDK.getApiClient().setRequestContext({ uid: apiData.us.data.UID });

        return ssSDK.getSubscriptionsList()
        .then((data) => {
            let subscriptionInfo = data["subscriptions"];
            if (subscriptionInfo && subscriptionInfo.length > 0) {
                dispatch(receiveSignatureStatus(true));
            } else {
                dispatch(receiveSignatureStatus(false));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(receiveSignatureStatus(false));
        });
    }
};

export const submit = () => {
    return (dispatch, getState) => {
        const { apiData } = getState();

        const { UIDSignature, signatureTimestamp } = gigyaAuthCredentials.getValue();
        dispatch(request());

        userSDK.deleteUser(apiData.us.data.UID, UIDSignature, signatureTimestamp).then(() => {
            dispatch(receive());
        }).catch(err => {
            if (err.errorCode === 403) {
                dispatch(fetchUserInfo());
            }
            dispatch(error(err));
        }).finally(() => {
            dispatch(cancelConfirm());
        })
    };
};

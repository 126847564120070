import React from 'react';
import { CategoryApiCardItem } from './CategoryApiCardItem';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withCollapse } from '../CategoryList/withCollapse';
import { CategoryList } from '../CategoryList/CategoryList';
import { DetailsItem } from '../Details/DetailsItem';
import { DetailsTitle } from '../Details/DetailsTitle';
import { CategoryCard } from '../CategoryCard/CategoryCard';
import { DetailsGroup } from '../Details/DetailsGroup';
import {
  resetAll,
  resetMailChimp, resetMDB, deleteNewsletter,
  resetSurveyGizmo, updateUser
} from './apiData.actions';
import { UserDetails } from '../UserDetails/UserDetails';

const CategoriesList = withCollapse(CategoryList);
const SubCategoriesList = withCollapse(({children}) => <div className="SubCategoryList">{children}</div>);
const Error = () => <div>Data utilgængelig</div>;
const SubCategoryCard = ({className, children, ...props}) => <CategoryCard className={classnames(className, 'SubCategoryCard')} details={() => children} {...props}/>;

export const List = ({userInfo: {userInfo, jwt}, apiData, deleteNewsletter, resetSurveyGizmo, resetAll, updateUser }) => {
  React.useEffect(() => {
    resetAll();
  }, []);

  let newsletters = [];
  let permissions = [];

  if (apiData.newsletters && apiData.newsletters.data && apiData.newsletters.data.length > 0) {
      const { data } = apiData.newsletters;
      newsletters = data.filter((newsletter) => newsletter.type === 'NEWSLETTER');
      permissions = data.filter((newsletter) => newsletter.type !== 'NEWSLETTER');
  }

  return (
    <CategoriesList getId={({title}) => title}>
      <CategoryCard title="Register for log-in oplysninger" details={() => (
        <UserDetails apiData={apiData} onEditSave={updateUser} />
      )}/>

      <CategoryCard
        title="Register for Kundeoplysninger, Marketing og nyhedsbreve"
        details={() => (
          <SubCategoriesList getId={({title}) => title}>

            <CategoryApiCardItem
              title="Samtykker"
              pending={apiData.newsletters.pending}
              hasError={apiData.newsletters.error}
              hasData={permissions.length > 0}
              renderError={Error}
              render={() => permissions.map(permission => (
                <DetailsGroup
                  key={permission.name}
                  deleteAction={() => deleteNewsletter(permission.id)}>
                  <DetailsTitle>{permission.name}</DetailsTitle>
                  <DetailsItem value={permission.time} label="Tid"/>
                  <DetailsItem value={permission.description} label="Beskrivelse"/>
                </DetailsGroup>
              ))}/>

            <CategoryApiCardItem
              title="Nyhedsbreve"
              pending={apiData.newsletters.pending}
              hasError={apiData.newsletters.error}
              hasData={newsletters.length > 0}
              renderError={Error}
              render={() => newsletters.map(newsletter => (
                  <DetailsGroup
                      key={newsletter.id}
                      deleteAction={() => deleteNewsletter(newsletter.id)}
                  >
                      <DetailsTitle>{newsletter.name}</DetailsTitle>
                      <DetailsItem value={newsletter.time} label="Tid"/>
                      <DetailsItem value={newsletter.description} label="Beskrivelse"/>
                  </DetailsGroup>
              ))}
            />

            <CategoryApiCardItem
              title="Spørgeskemaer/konkurrencer"
              pending={apiData.surveyGizmo.pending}
              hasError={apiData.surveyGizmo.error}
              hasData={apiData.surveyGizmo.data && apiData.surveyGizmo.data.length > 0}
              renderError={Error}
              render={() => apiData.surveyGizmo.data.map(survey => (
                <DetailsGroup key={`${survey.response_id}${survey.survey_id}`}>
                  <DetailsItem value={survey.city} label="By"/>
                  <DetailsItem value={survey.country} label="Country"/>
                  <DetailsItem value={survey.date} label="Dato"/>
                  <DetailsItem value={survey.date_submitted} label="indsendelsesdato"/>
                  <DetailsItem value={survey.dma} label="Udbyder"/>
                  <DetailsItem value={survey.email} label="E-mail"/>
                  <DetailsItem value={survey.ip_address} label="IP adresse"/>
                  <DetailsItem value={survey.language} label="Sprog"/>
                  <DetailsItem value={survey.latitude} label="breddegrad"/>
                  <DetailsItem value={survey.longitude} label="Længdegrad"/>
                  <DetailsItem value={survey.postal} label="Post nr."/>
                  <DetailsItem value={survey.referer} label="Url"/>
                  <DetailsItem value={survey.region} label="Region"/>
                  <DetailsItem value={survey.response_id} label="Response_id"/>
                  <DetailsItem value={survey.survey_id} label="Survey_id"/>
                  <DetailsItem value={survey.survey_name} label="Navn på survey"/>
                  <DetailsItem value={survey.user_agent} label="User agent"/>

                  <DetailsItem label="Dine svar" className="mt-2"
                               value={survey.survey_data.map(qa => <DetailsItem key={qa.q} label={qa.q} value={qa.a}/>)}/>
                </DetailsGroup>
              ))}/>
          </SubCategoriesList>
        )}/>
    </CategoriesList>
  );
}

const mapStateToProps = ({apiData, userInfo}) => ({
  apiData,
  userInfo
});

const mapDispatchToProps = dispatch => ({
  resetMDB: () => dispatch(resetMDB()),
  deleteNewsletter: (permissionId) => dispatch(deleteNewsletter(permissionId)),
  resetMailChimp: () => dispatch(resetMailChimp()),
  resetSurveyGizmo: () => dispatch(resetSurveyGizmo()),
  resetAll: () => dispatch(resetAll()),
  updateUser: (data) => dispatch(updateUser(data)),
});

export const CategoryApiList = connect(mapStateToProps, mapDispatchToProps)(List);

import { TOGGLE_USER_DETAILS_EDIT, RESET_USER_DETAILS } from './userDetails.actions';

export const defaultUserDetailsState = () => ({
  isEdit: false,
});

export function userDetails(state = defaultUserDetailsState(), action) {
  switch (action.type) {
    case TOGGLE_USER_DETAILS_EDIT:
      return { ...state, isEdit: !state.isEdit };
    case RESET_USER_DETAILS:
      return defaultUserDetailsState();
    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DetailsItem } from '../Details/DetailsItem';
import { DetailsGroup } from '../Details/DetailsGroup';
import { UserDetailsForm } from '../UserDetailsForm/UserDetailsForm';
import { toggleUserDetailsEdition } from './userDetails.actions';

const getBirthDayDate = (apiData) => {
  if (apiData.us.data?.birthDay && apiData.us.data?.birthMonth && apiData.us.data?.birthYear) {
    return `${apiData.us.data.birthYear}-${apiData.us.data.birthMonth}-${apiData.us.data.birthDay}`;
  }
  return undefined;
}

const getName = (apiData) => {
  if (apiData.us.data?.firstName || apiData.us.data?.lastName) {
    return `${apiData.us.data.firstName || ''} ${apiData.us.data.lastName || ''}`.trim();
  }
  return undefined;
}

const UserDetailsComponent = ({ toggleEdition, userDetails, apiData, onEditSave = () => {} }) => {
  const handleSubmit = (data) => {
    onEditSave(data);
  };

  return (
    <DetailsGroup editAction={toggleEdition}>
      {userDetails.isEdit ? (
        <UserDetailsForm loading={apiData.us.pending} onSubmit={handleSubmit} initialValues={apiData.us.data} />
      ) : (
        <>
          <DetailsItem label="Navn" value={getName(apiData)}/>
          <DetailsItem label="E-mail" value={apiData.us.data?.email}/>
          <DetailsItem label="Fødselsdag" value={getBirthDayDate(apiData)}/>
          <DetailsItem label="Adresse" value={apiData.us.data?.address?.addressLine}/>
          <DetailsItem label="Co" value={apiData.us.data?.address?.co}/>
          <DetailsItem label="Postnummer" value={apiData.us.data?.address?.postalCode}/>
          <DetailsItem label="By" value={apiData.us.data?.address?.city}/>
          <DetailsItem
            label="Telefon"
            value={(apiData.us.data?.phone || apiData.us.data?.cellPhone) ? [apiData.us.data?.phone, apiData.us.data?.cellPhone].join(',') : ''}
          />
          <DetailsItem label="Fødselsdag" value={apiData.us.data?.birthDay}/>
          <DetailsItem label="Fødselsmåned" value={apiData.us.data?.birthMonth}/>
          <DetailsItem label="Fødselsår" value={apiData.us.data?.birthYear}/>
          <DetailsItem label="Køn" value={apiData.us.data?.gender}/>
        </>
      )}
    </DetailsGroup>
  );
};

UserDetailsComponent.propTypes = {
  apiData: PropTypes.object.isRequired,
  onEditSave: PropTypes.func,
};

const mapStateToProps = ({ userDetails }) => ({
  userDetails,
});

const mapDispatchToProps = dispatch => ({
  toggleEdition: () => dispatch(toggleUserDetailsEdition()),
});

export const UserDetails = connect(mapStateToProps, mapDispatchToProps)(UserDetailsComponent);

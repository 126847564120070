import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import './Form.scss';

export const Select = ({
  name,
  label = name,
  value,
  onChange,
  pending,
  required,
  children,
  columnClassName,
  ...otherProps
}) => (
  <div className="row justify-content-center">
    <div className={columnClassName || 'col-sm-6'}>
      <FormGroup>
        <Label for={name} className="control-label FormInput-label">
          {label}
          {required ? <span className="ml-1 text-danger">*</span> : null}
        </Label>
        <select
          id={name}
          name={name}
          className="form-control"
          autoComplete="off"
          value={value}
          onChange={onChange}
          {...otherProps}
        >
          {children}
        </select>
      </FormGroup>
    </div>
  </div>
);

Select.Option = ({ value, children }) => <option value={value}>{children}</option>;

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  required: PropTypes.bool,
  columnClassName: PropTypes.string,
};

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './src/app';
import { scriptLoader } from './src/common/scriptLoader';

const config = {
  gigyaApiKey: process.env.GIGYA_API_KEY || '',
  trackingId: process.env.GA_TRACKING_ID || '',
};

scriptLoader(`https://cdns.gigya.com/js/gigya.js?apikey=${config.gigyaApiKey}`, true).then(() => {
  ReactDOM.render(<App config={config}/>, document.getElementById('content'));
}).catch(err => {
  console.error('Error loading Gigya script', err);
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { FormInput } from '../Form/FormInput';
import { Select } from '../Form/Select';
import SubmitButton from '../SubmitButton/SubmitButton';
import { birthDateValidator } from '../common/birthDateValidator';

const lettersOnlyRegex = /^[a-zA-ZæøåÆØÅ.\\\-,&\\/:\'+ÄäÁáÂâÃãËëÈèÉéÖöÓóÑñÜüÚú()_ÿß ]+$/;
const danishPhoneRegex = /^((\(?\+45\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})$/;

const schema = Joi.object({
  firstName: Joi.string().allow(null).regex(lettersOnlyRegex).max(100).messages({
    '*': 'Fornavn er ikke gyldig.',
  }),
  lastName: Joi.string().allow(null).regex(lettersOnlyRegex).max(100).messages({
    '*': 'Efternavn er ikke gyldig.',
  }),
  phone: Joi.string().allow(null).regex(danishPhoneRegex).messages({
    '*': 'Indtast venligst et 8-cifret telefonnummer.',
  }),
  gender: Joi.string().valid('m', 'f', 'u').allow(null),
  birthYear: Joi.number().min(1).allow(null).messages({
    '*': 'Datoen er ikke gyldig.',
  }),
  postalCode: Joi.string().max(8).allow(null).messages({
    '*': 'Ugyldigt postnummer.',
  }),
}).unknown(false).custom(birthDateValidator).message('Datoen er ikke gyldig.');

export const UserDetailsForm = ({ initialValues = {}, onSubmit = () => {}, loading }) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const validate = (data) => {
    const violations = schema.validate(data, { abortEarly: false });

    if (!violations.error) {
      return null;
    }

    const errors = {};
    violations.error.details.map((violation) => {
      errors[violation.path[0] || 'global'] = violation.message;
    });
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate(data);
    setErrors(errors || {});
    !errors && onSubmit(data);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || null,
    });
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <FormInput
        columnClassName="col-12"
        label="Navn"
        name="firstName"
        defaultValue={initialValues?.firstName}
        onChange={handleChange}
        error={errors.firstName}
      />
      <FormInput
        columnClassName="col-12"
        label="Efternavn"
        name="lastName"
        defaultValue={initialValues?.lastName}
        onChange={handleChange}
        error={errors.lastName}
      />
      <FormInput columnClassName="col-12" label="E-mail" defaultValue={initialValues?.email} disabled />
      <FormInput
        columnClassName="col-12"
        label="Postnummer"
        name="postalCode"
        defaultValue={initialValues?.address?.postalCode}
        onChange={handleChange}
        error={errors.postalCode}
      />
      <FormInput
        columnClassName="col-12"
        label="Telefon"
        name="phone"
        defaultValue={initialValues?.phone}
        onChange={handleChange}
        error={errors.phone}
      />
      <FormInput
        columnClassName="col-12"
        label="Fødselsår"
        name="birthYear"
        defaultValue={initialValues?.birthYear}
        onChange={handleChange}
        error={errors.birthYear}
      />
      <Select
        columnClassName="col-12"
        label="Køn"
        name="gender"
        defaultValue={initialValues?.gender}
        onChange={handleChange}
        error={errors.gender}
      >
        <Select.Option value="">-</Select.Option>
        <Select.Option value="m">m</Select.Option>
        <Select.Option value="f">f</Select.Option>
        <Select.Option value="u">u</Select.Option>
      </Select>
      {errors.global && (<p className="text-danger">{errors.global}</p>)}
      <SubmitButton loading={loading}>Gem</SubmitButton>
    </form>
  );
};

UserDetailsForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/unchecked.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/checked.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".Checkbox{display:inline-block;vertical-align:middle;margin-right:5px;position:relative;overflow:hidden}.Checkbox input{opacity:0;position:absolute;left:0;top:0;width:100%;height:100%;margin:0}.Checkbox .fake-input{width:23px;height:23px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0}.Checkbox input:checked+.fake-input{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}\n", ""]);
// Exports
module.exports = exports;

const GigyaAuthCredentials = function () {
    let _UID = null;
    let _UIDSignature = null;
    let _signatureTimestamp = null;

    function setData({ UID, UIDSignature, signatureTimestamp }) {
        if (typeof UID !== 'undefined') {
            _UID = UID;
        }

        if (typeof UIDSignature !== 'undefined') {
            _UIDSignature = UIDSignature;
        }

        if (typeof signatureTimestamp !== 'undefined') {
           _signatureTimestamp = signatureTimestamp;
        }
    }

    return {
        setValue(value = {}) {
            setData(value);
        },

        getValue() {
            return {
                UID: _UID,
                UIDSignature: _UIDSignature,
                signatureTimestamp: _signatureTimestamp,
            };
        },
    };
};

const gigyaAuthCredentials = new GigyaAuthCredentials();

export default gigyaAuthCredentials;

import * as UserSDK from '@berlingske-media/bm.node-module.user-profil-sdk';
import { authProvider } from './authProvider';

const userSDK = UserSDK.configure(
    process.env.USER_PROFILE_URL,
    authProvider,
    'gsp',
);

export { userSDK };

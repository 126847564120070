import { actionBuilder } from '../common/redux-utils';
import { toggleUserDetailsEdition } from '../UserDetails/userDetails.actions';
import { nssdk } from '../common/client/newsletter';
import { userSDK } from '../common/client/user';

export const API_DATA_REQUEST = '[api data] request';
export const API_DATA_UPDATE_REQUEST = '[api data] update request';
export const API_DATA_RECEIVE = '[api data] receive';
export const API_DATA_ERROR = '[api data] error';
export const API_DATA_UPDATE_ERROR = '[api data] update error';
export const API_DATA_RESET = '[api data] reset';
export const API_DATA_MDB_SUFFIX = 'MDB';
export const API_DATA_USER_SERVICE_SUFFIX = 'US';
export const API_DATA_MAIL_CHIMP_SUFFIX = 'MailChimp';
export const API_DATA_SURVEY_GIZMO_SUFFIX = 'SurveyGizmo';
export const API_DATA_NEWSLETTER_SUFFIX = 'Newsletter';

export const fetchNewsletters = () => async (dispatch, getState) => {
  const {userInfo} = getState();

  dispatch({
    type: `${API_DATA_REQUEST} ${API_DATA_NEWSLETTER_SUFFIX}`
  });

  try {
    const signups = await nssdk.users.getUserSignups(userInfo.userInfo.UID);
    const permissionsIds = signups.map((signup) => signup.permissionId);
    const permissions = await nssdk.permissions.getMany(undefined, undefined, undefined, permissionsIds);

    const data = signups.map((signup) => {
      const permission = permissions.find((permission) => permission.permissionId === signup.permissionId);

      if (!permission) {
        return null;
      }

      const [latestRevision] = permission.permissionRevision;

      return {
        id: permission.permissionId,
        name: permission.name,
        description: latestRevision ? latestRevision.consentText : '',
        time: permission.frequency,
        type: permission.type,
      };
    });

    dispatch({
      type: `${API_DATA_RECEIVE} ${API_DATA_NEWSLETTER_SUFFIX}`,
      data: data.filter((p) => p && p.type !== 'SERVICE'),
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: `${API_DATA_ERROR} ${API_DATA_NEWSLETTER_SUFFIX}`,
      error: e.message,
    });
  }
};

export const deleteNewsletter = (permissionId) => async (dispatch, getState) => {
  const {userInfo, apiData} = getState();

  dispatch({
    type: `${API_DATA_REQUEST} ${API_DATA_NEWSLETTER_SUFFIX}`
  });

  try {
    await nssdk.signups.remove(permissionId, userInfo.userInfo.UID, {
      campaignId: 3422,
      changedSource: 'GSP',
    });

    dispatch({
      type: `${API_DATA_RECEIVE} ${API_DATA_NEWSLETTER_SUFFIX}`,
      data: apiData.newsletters.data.filter((p) => p.id !== permissionId),
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: `${API_DATA_ERROR} ${API_DATA_NEWSLETTER_SUFFIX}`,
      error: e.message,
    });
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  const {userInfo, apiData} = getState();

  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    birthYear: data.birthYear,
    gender: data.gender,
    address: {
      postalCode: data.postalCode,
    },
  };

  dispatch({
    type: `${API_DATA_UPDATE_REQUEST} ${API_DATA_USER_SERVICE_SUFFIX}`
  });

  try {
    await userSDK.updateByUID(apiData.us.data.UID || userInfo.userInfo.UID, payload);
    dispatch({
      type: `${API_DATA_RECEIVE} ${API_DATA_USER_SERVICE_SUFFIX}`,
    });
    dispatch(toggleUserDetailsEdition());
  } catch (e) {
    dispatch({
      type: `${API_DATA_UPDATE_ERROR} ${API_DATA_USER_SERVICE_SUFFIX}`,
      error: e,
    })
  }
};

export const fetchUser = () => async (dispatch, getState) => {
  const {userInfo} = getState();

  dispatch({
    type: `${API_DATA_REQUEST} ${API_DATA_USER_SERVICE_SUFFIX}`
  });

  try {
    const data = await userSDK.getBySSOUID(userInfo.userInfo.UID)
    dispatch({
      type: `${API_DATA_RECEIVE} ${API_DATA_USER_SERVICE_SUFFIX}`,
      data: data.body,
    })
  } catch (e) {
    dispatch({
      type: `${API_DATA_ERROR} ${API_DATA_USER_SERVICE_SUFFIX}`,
      error: e,
    })
  }
}

export const resetMDB = actionBuilder(`${API_DATA_RESET} ${API_DATA_MDB_SUFFIX}`);
export const resetUser = actionBuilder(`${API_DATA_RESET} ${API_DATA_USER_SERVICE_SUFFIX}`);
export const resetMailChimp = actionBuilder(`${API_DATA_RESET} ${API_DATA_MAIL_CHIMP_SUFFIX}`);
export const resetSurveyGizmo = actionBuilder(`${API_DATA_RESET} ${API_DATA_SURVEY_GIZMO_SUFFIX}`);
export const resetNewsletters = actionBuilder(`${API_DATA_RESET} ${API_DATA_NEWSLETTER_SUFFIX}`);

export const resetAll = () => {
  return dispatch => {
    dispatch(resetMDB());
    dispatch(resetNewsletters());
    dispatch(resetUser());
    dispatch(resetMailChimp());
    dispatch(resetSurveyGizmo());
  }
};

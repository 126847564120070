import { actionBuilder } from './redux-utils';
import gigyaAuthCredentials from './gigya-auth-credentials';

export const REQUEST_USER_INFO = '[user info] request';
export const RECEIVE_USER_INFO = '[user info] receive';
export const RESET_USER_INFO = '[user info] reset';
export const VERIFY_USER = '[user info] verify';

export const SCHEDULE_REFETCH = '[user info] schedule refetch';
export const CANCEL_REFETCH = '[user info] cancel refetch';

export const requestUserInfo = actionBuilder(REQUEST_USER_INFO);
export const receiveUserInfo = ({UID, profile, errorCode}) => ({
  type: RECEIVE_USER_INFO,
  userInfo: {UID, profile, errorCode},
});
export const resetUserInfo = actionBuilder(RESET_USER_INFO);
export const verifyUser = actionBuilder(VERIFY_USER);

export const fetchUserInfo = () => {
  return (dispatch, getState) => {
    dispatch(requestUserInfo());

    return new Promise(fulfill => {
      gigya.accounts.getAccountInfo({
        include: 'profile',
        extraProfileFields: 'address,phones',
        callback: userInfo => {
          if (userInfo.errorCode === 0) {
            gigyaAuthCredentials.setValue(userInfo);
          }

          dispatch(receiveUserInfo(userInfo));
          fulfill();
        }
      });
    });
  }
};

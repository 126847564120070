const dateNow = new Date();

const cutoffYearMax = dateNow.getFullYear() - 15;

export function birthDateValidator(birthDateSchema) {
  const { birthDay, birthMonth, birthYear } = birthDateSchema;
  if (birthYear && (birthYear < 1900 || birthYear > cutoffYearMax) ) {
    throw new Error('Invalid Date');
  }
  if ( (birthDay && !birthMonth) || (!birthDay && birthMonth) || (birthDay && birthMonth && !birthYear) ) {
    throw new Error('Incomplete Date');
  }
  if (birthDay && birthMonth && birthYear) {
    const dateObj = new Date(birthYear, birthMonth - 1, birthDay);
    if (dateObj.getDate() !== birthDay) {
      throw new Error('Invalid Date');
    }
  }
  return birthDateSchema;
}
